<template>
  <div class="row">
    <div class="form-group col-md-4">
      <label for="temporada">Temporada</label>
      <select
        v-model="selectedTemporada"
        class="form-control form-control-sm"
        @change="onSelectTemporada"
      >
        <option value="" disabled>Seleccione temporada</option>
        <option value=1>Invierno</option>
        <option value=2>Verano</option>
      </select>
    </div>

    <div v-if="selectedTemporada" class="form-group col-md-4">
      <label for="horas_vigencia">Horas de Vigencia</label>
      <input
        type="number"
        class="form-control form-control-sm"
        v-model="horasVigencia"
        min="1"
      />
    </div>

    <div v-if="selectedTemporada && horasVigencia" class="col-md-2">
      <button
        class="btn btn-success"
        @click="guardarTemporada"
        :disabled="!horasVigencia"
      >
        Guardar Temporada
      </button>
    </div>

    <div  class="col-md-12 mt-3">
      <h5>Temporadas Registradas:</h5>
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Temporada</th>
            <th>Horas de Vigencia</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(temporada, index) in this.$parent.ruta.temporadas" :key="index">
            <td>{{ temporada.pivot.temporada_id == 1 ? 'Invierno' : temporada.pivot.temporada_id == 2 ? 'Verano' : 'Desconocido' }}</td>
            <td>{{ temporada.pivot.horas_vigencia }} horas</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Temporada",

  data() {
    return {
      selectedTemporada: "", 
      horasVigencia: null,
      temporadas: [],
      ruta_id :null,
    };
  },
  methods: {
    getIndex() {
      this.form.ruta_id = this.$route.params.id;
      this.ruta_id = this.form.ruta_id;
      axios
        .get("/api/admin/rutas/show/" + this.form.ruta_id)
        .then((response) => {
        });
    },
    onSelectTemporada() {
      // Limpiar las horas de vigencia cuando se cambia la temporada
      const temporadaExistente = this.temporadas.find(
        (temp) => temp.nombre === this.selectedTemporada
      );
      if (temporadaExistente) {
        this.horasVigencia = temporadaExistente.horasVigencia; // Cargar las horas existentes
      } else {
        this.horasVigencia = null; // Si no existe, mantener el campo vacío
      }
    },
    guardarTemporada() {
  const temporadaExistente = this.temporadas.find(
    (temp) => temp.temporada_id === this.selectedTemporada
  );

  const temporadaData = {
    ruta_id: this.$parent.id, 
    temporada_id: this.selectedTemporada,
    horas_vigencia: this.horasVigencia,
  };

  if (temporadaExistente) {
 
    temporadaExistente.horasVigencia = this.horasVigencia;

    axios
      .post(`api/admin/rutas/${this.$parent.id}/temporadas`, temporadaData)
      .then((response) => {
        this.$swal("Éxito", "Horas de vigencia actualizadas", "success");
        this.$parent.getIndex();
      })
      .catch((error) => {
        this.$swal("Error", error.response.data.message || "Ocurrió un error", "error");
      });
  } else {
  
    this.temporadas.push({
      temporada_id: this.selectedTemporada,
      horasVigencia: this.horasVigencia,
    });

    
    axios
      .post(`api/admin/rutas/${this.$parent.id}/temporadas`, temporadaData)
      .then((response) => {
        this.$swal("Éxito", "Temporada registrada", "success");
      })
      .catch((error) => {
        this.$swal("Error", error.response.data.message || "Ocurrió un error", "error");
      });
  }

  this.selectedTemporada = "";
  this.horasVigencia = null;
},

  },
};
</script>

<style scoped>
.table {
  width: 100%;
  margin-top: 15px;
}
</style>
